import { Organisation } from "gogoo-common/content/Organisation"
import { OrganisationDashboardResult } from "gogoo-common/admin/Organisation"
import { AdminApi, ResourceUrl } from "./config"
import { API, Auth } from "aws-amplify"
import { AdminUser } from "gogoo-common/admin/AdminUser"
import { AdminVenuesList, VenueDashboardResult } from "gogoo-common/admin/Venues"
import EventMetaData from "gogoo-common/content/EventMetaData"
import { DetailedEventResponse, KultunautEvent, PremiumVenueMetaData } from "gogoo-common/content/Kultunaut"
import { SearchEvent } from "gogoo-common/content/SearchParams"
import { BlogPost } from "gogoo-common/content/BlogPost"
import { CreateBlogPost } from "gogoo-common/admin/BlogPost"
import { Banner, CreateBanner } from "gogoo-common/content/Banner"


const listUserOrganisations = async (): Promise<Organisation[] | undefined> => {
    return await API.get(AdminApi, 'organizations', {})
}

const getOrgansationsDashboard = async (organisationId: string): Promise<OrganisationDashboardResult> => {
    return await API.get(AdminApi, 'organizations/dashboard', {
        queryStringParameters: {
            organisationId
        }
    })
}

const getAdminUser = async (): Promise<AdminUser> => {
    return await API.get(AdminApi, 'me', {})
}

const listUserVenues = async (): Promise<AdminVenuesList | undefined> => {
    return await API.get(AdminApi, 'venues', {})
}

const getVenueDashboard = async (venueId: number): Promise<VenueDashboardResult> => {
    return await API.get(AdminApi, 'venue/dashboard/' + venueId, {})
}

const addEventMetaData = async (eventMetaData: EventMetaData) => {
    return await API.post(AdminApi, 'events/metadata/', { body: eventMetaData })
}

const getEventDashboard = async (eventId: number): Promise<DetailedEventResponse> => {
    return await API.get(AdminApi, 'event/dashboard/' + eventId, {})
}

const signup = async (): Promise<AdminUser> => {
    const currentUser = await Auth.currentAuthenticatedUser()
    const email = currentUser.attributes.email
    const name = currentUser.attributes.name
    const body = { email, name }
    await API.post(AdminApi, 'me', { body })

    return await getAdminUser()
}


const approveVenueAccess = async (venueId: number, adminUserId: string): Promise<boolean> => {
    return (await API.post(AdminApi, 'admin/venue/approve/' + venueId, { body: { userId: adminUserId } }))
}

const searchEvent = async (params: SearchEvent): Promise<KultunautEvent[]> => {
    return (await API.post(AdminApi, 'events/search', { body: params }))
}

const updateOrganisation = async (organisationUpdates: Organisation): Promise<Organisation | undefined> => {

    const body = {...organisationUpdates, data: undefined}

    return await API.put(AdminApi, 'organizations', {
        queryStringParameters: {
            organisationId: organisationUpdates.organizationId
        },
        body
    })
}

const getBlogPostById = async (id: string): Promise<BlogPost> => {
    return (await API.get(AdminApi, 'blogs/' + id, {}))
}

const updateBlogPost = async (params: BlogPost): Promise<BlogPost> => {
    return (await API.put(AdminApi, 'blogs', { body: params }))
}

const createBlogPost = async (params: CreateBlogPost): Promise<BlogPost> => {
    return (await API.post(AdminApi, 'blogs/', { body: params }))
}

const getUploadLink = async (): Promise<{ uploadUrl: string; key: string }> => {
    return (await API.post(AdminApi, 'files/upload', {}))
}

const uploadFile = async (file: File): Promise<string | undefined> => {
    try {
        const item = await getUploadLink()
        await fetch(item.uploadUrl, {
            method: 'PUT',
            body: file
        })
        return ResourceUrl + item.key
    } catch (error) {
        return undefined
    }
}

const createBanner = async (params: CreateBanner): Promise<Banner> => {
    return (await API.post(AdminApi, 'banners', { body: params }))
}

const updateBanner = async (params: Banner): Promise<Banner> => {
    return (await API.put(AdminApi, 'banners/', { body: params }))
}

const updatePremiumVenueMetaData = async (premium: PremiumVenueMetaData): Promise<void> => {
    await API.put(AdminApi, 'venues/premium-venue-meta-data', { body: premium })
}

export default {
    listUserOrganisations, getOrgansationsDashboard,
    getAdminUser, getVenueDashboard, listUserVenues,
    addEventMetaData, getEventDashboard,
    signup, approveVenueAccess, searchEvent,
    updateOrganisation, getBlogPostById,
    updateBlogPost, createBlogPost,
    uploadFile, createBanner, updateBanner,
    updatePremiumVenueMetaData
}